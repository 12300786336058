import {PropertyType} from "./types/Property"

export function standarizeName(name: string) {
   const normalized = name
      .normalize("NFD") // Normalize to decomposed form
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritic marks (tildes and such)
      .toLowerCase()
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "-")

   return normalized
}

export const getTypeForURL = (type: string) => {
   return type === PropertyType.HOUSE
      ? "casas"
      : type === PropertyType.APARTMENT
      ? "departamentos"
      : type === PropertyType.COMMERCIAL
      ? "locales-oficinas"
      : "terrenos"
}

export const isMobile = () => {
   return window.innerWidth <= 768
}

type CacheKey = "fetchedPartners" | "properties" | "condos"

/**
 * Clears whatever we have stored in localStorage as cache.
 */
export const clearCache = (keys?: CacheKey | CacheKey[]) => {
   if (!keys) {
      keys = ["fetchedPartners", "condos", "properties"]
      keys.forEach((key) => {
         localStorage.removeItem(key)
      })
      return
   }

   if (Array.isArray(keys)) {
      keys.forEach((key) => {
         localStorage.removeItem(key)
      })
      return
   }

   localStorage.removeItem(keys)
}
