import axios from "axios"
import {API_URL} from "../constants"

type collection = "inmuebles" | "condominios"

// Check if property name has been taken
export const checkNameTaken = async (name: string, collection: collection) => {
   const res = await axios.get(`${API_URL}/utils/nameTaken?name=${name}&collection=${collection}`)
   return res.data.nameTaken
}

// Rename img category
export const renameCategory = async (
   collection: collection,
   id: string,
   oldName: string,
   newName: string,
) => {
   const res = await axios({
      method: "patch",
      url: `${API_URL}/utils/${collection}/${id}/renameImgCategory`,
      data: {oldName, newName},
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
   return res.status
}
